<template>
  <div class="d-flex flex-column h-100 px-4">
    <div class="d-flex align-center justify-center mb-2">
      <v-icon class="mr-2" color="var(--primary-black-color)" size="50">mdi-check-circle-outline</v-icon>
      <div class="page-title">Order Confirmed</div>
    </div>
    <!-- <div class="px-1 mb-2 font-1r font-weight-bold">
      Last Order: 
    </div> -->
    <div class="order-wrap pa-3 mb-3">
      <div class="d-flex justify-space-between">
        <div class="font-1dot25r font-weight-bold">Order No.</div>
        <div class="font-1dot25r font-weight-bold">{{ order.cdate ? setTimeZone(order.cdate, timeFormat) : '-' }}</div>
      </div>
      <div class="font-3dot5r font-weight-bold text-center my-3">
        {{ order.seq }}
      </div>
      <div v-if="!isUndefinedNullOrEmpty(order.curSeq)" class="font-1r font-weight-bold text-center mb-2">
        Current queue: {{ order.curSeq }}
      </div>
      <div class="font-1dot25r font-weight-bold text-center">
        Please wait for your turn to pay.
      </div>
    </div>
    <div class="order-wrap pa-4">
      <div class="d-flex justify-space-between">
        <!-- <div class="font-1dot5r font-weight-bold">Ordered Items</div> -->
      </div>
      <!-- <template v-for="(set, setIndex) in order.data">
        <div :class="{ 'mb-2' : order.data.length > 1 }" :key="setIndex">
          <div v-if="isUndefinedNullOrEmpty(set.items)" class="font-1dot25r font-weight-bold">
            {{ `${set.label} x ${set.qty}` }}
          </div>
          <div v-else class="font-1dot25r font-weight-bold mb-2">
            {{ `${set.label} x ${set.qty}` }}
          </div>
          <template v-for="(item, index) in set.items">
            <ul :key="index">
              <li class="font-1dot25r">{{ `${item.name} x ${item.qty}` }}</li>
            </ul>
          </template>
        </div>
      </template> -->

      <template v-for="(set, setIndex) in order.items">
        <div :key="setIndex" :class="{ 'mb-1': order.items.length > 1 }">
          <div class="font-1dot1r font-weight-medium">
            {{ set }}
          </div>
        </div>
      </template>

      <v-divider class="my-2"></v-divider>

      <template v-if="order.discount > 0">
        <div class="d-flex justify-space-between align-center mb-2">
          <div class="font-weight-medium font-1dot1r">{{ $t('orders.lbl.subtotal') }}</div>
          <div class="font-weight-medium font-1dot1r">{{ `${$t('lbl.rm')}${subtotal}` }}</div>
        </div>

        <div class="d-flex justify-space-between align-center mb-2">
          <div class="font-weight-medium font-1dot1r">{{ 'Discount' }}</div>
          <div class="font-weight-medium font-1dot1r">{{ `- ${$t('lbl.rm')}${priceFormat(order.discAmt)}` }}</div>
        </div>
      </template>

      <div class="d-flex justify-space-between align-center">
        <div class="font-weight-medium font-1dot1r">{{ $t('lbl.total') }}</div>
        <div class="font-weight-medium font-red-common font-1dot2r">{{ `${$t('lbl.rm')}${priceFormat(order.amt)}` }}</div>
      </div>

    </div>
    <div class="my-3">
      <AppBtn class="btn-black" block :height="42" @click="orderAgain">{{ 'Order Again' }}</AppBtn>
    </div>
    <!-- <ReferralShareModal v-if="referralShareModalShow" v-model="referralShareModalShow" />
    <v-fab-transition>
      <v-btn fab large dark color="pink" fixed right bottom @click="referralShareModalShow = true">
        <v-icon>mdi-gift</v-icon>
      </v-btn>
    </v-fab-transition> -->
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { orderApi } from '@/api/order';
// import ReferralShareModal from '@/views/Referral/Share/components/Modal';
export default {
  name: 'OrderDetail',
  components: {
    // ReferralShareModal,
  },
  data() {
    return {
      orderHistory: {},
      order: {
        amt: 0,
      },
      referralShareModalShow: false,
    };
  },
  computed: {
    ...mapState({
      timeFormat: state => state.app.timeFormat,
    }),
    ...mapGetters(['getOrderHistory', 'getOrderUserId']),
    // order() {
    //   return this.getOrderHistory.find(item => item.orderId == this.$route.params.orderId);
    // },
    subtotal() {
      const nettDeliveryFee = this.order.deliveryFee - this.order.discDeliveryFee;
      return this.priceFormat(this.order.amt - nettDeliveryFee + this.order.discAmt);
    },
  },
  async created() {
    this.SET_DRAWER_TITLE('Order Detail');
    this.order = await this.getDetail(this.$route.params.orderId, this.getOrderUserId);

    const orderData = {
      amt: this.order.amt,
      deliveryFee: this.order.deliveryFee,
      orderId: this.order.id,
      seq: this.order.seq,
      curSeq: this.order.curSeq,
      userId: this.getOrderUserId,
      cdate: this.order.cdate,
      items: this.order.items,
      cancelled: 0,
      status: this.order.status,
      channel: this.order.channel,
      storeName: this.order.store,
      locName: '',
    };
    const histObj = this.getOrderHistory.find(item => item.orderId == orderData.orderId);

    if (this.isUndefinedNullOrEmpty(histObj)) {
      this.setOrderHistory(orderData);
    } else {
      this.updateOrderHistory(orderData);
    }

    this.clearCart();
    this.clearPlacedCart();

    this.clearFocCart();
    this.clearPlacedFocCart();
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE']),
    ...mapActions([
      'setOrderHistory',
      'updateOrderHistory',
      'clearCart',
      'clearPlacedCart',
      'clearFocCart',
      'clearPlacedFocCart',
    ]),
    orderAgain() {
      this.SET_DRAWER_TITLE('');
      this.navigatePush('MenuAll');
    },
    getDetail(orderId, userId) {
      const params = {
        id: orderId,
        userId,
      };
      return orderApi.detail(params).then(res => {
        if (this.isUndefinedNullOrEmpty(res.data)) {
          this.showError(this.$t('msg.error.recordNotFound'));
          return this.redirectToMenu();
        }
        return res.data;
      });
    },
    redirectToMenu() {
      setTimeout(() => {
        this.overlay = false;
        this.navigateReplace('MenuAll');
      }, 1500);
    },
  },
};
</script>
<style lang="scss" scoped>
.page-title {
  color: var(--primary-black-color);
  font-size: 1.75rem;
  font-weight: 600;
}
.order-wrap {
  background-color: #fff;
  border: 5px solid var(--primary-black-color);
  border-radius: 5px;
}
</style>
